<template>
  <div>
    <el-card class="import-error">
      <el-row class="row-bg" justify="center ">
        <el-col :span="24">
          <div class="grid-content bg-purple-dark flex-column-all-center">
            <div><span class="iconfont iconcuowu"></span>导入失败</div>
          </div>
        </el-col>
      </el-row>
         <el-row class="row-bg1" justify="center ">
        <el-col :span="24">
             <el-table
                  :data="errorData"
                  border
                  style="width: 100% margin:10px;"
                  max-height="750"
                  :header-cell-style="{
                    background: '#F5F7FA',
                    color: '#606266',
                  }"
                >
                  <el-table-column prop="name" label="姓名">
                  </el-table-column>
                  <el-table-column prop="phone" label="手机号">
                  </el-table-column>
                  <el-table-column prop="email" label="邮箱">
                  </el-table-column>
                  <el-table-column v-if="type==1" prop="department" label="部门">
                  </el-table-column>
                  <el-table-column v-else prop="company_name" label="公司">
                  </el-table-column>
                  <el-table-column prop="position" label="职位"></el-table-column>
                  <el-table-column prop="reason"  label="失败原因"> 
                  </el-table-column>
                </el-table>
        </el-col>
      </el-row>
      <div style="text-align:center">
        <!-- d8d6d0b57e52a04db17ab05eaf2f853c -->
      <el-button v-if="this.type==1" class="addBtn error-import" @click="ErrorReport">
        下载错误报告
        </el-button>
          <el-button v-if="this.type==2" class="addBtn error-import" @click="ErrorReportExternal">
        下载错误报告
        </el-button>
      <el-button class="addBtn" @click="ReImport">重新导入</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import request from "@request/api/index";
export default {
  components: {},
  props: {},
  watch: {},
  data() {
    return {
        errorData:[],
        type:1
    };
  },
  created() {
    this.errorData=this.$route.query.errorData
    this.type=this.$route.query.type
    // console.log(this.$route.query.errorData)
  },

  methods: {
    ReImport(){
        if (window.history.length <= 1) {
        this.$router.replace('importPage');
      }else{
        this.$router.back();
      }
    },
  async  ErrorReport(){
    let params={ }
   await request.organization.requestErrorReport({ params })
    },
     async  ErrorReportExternal(){
    let params={ }
   await request.organization.requestExternalErrorReport({ params })
    },

  },
};
</script>
<style lang="scss" scoped>
.import-error {
  padding: 65px 100px 15px 100px;
.row-bg {
  background-color: #f9fafc;
  height: 71px;
  line-height: 71px;
  font-weight: 400;
  .iconcuowu{
      padding-right:20px;
      color:#f5222d;
      font-size: 14px;
  }
}
.row-bg1{
    margin-top:15px;
    height: 600px;
     background-color: #f9fafc;
}
.error-import{
  width: 136px !important;
}
}
</style>
